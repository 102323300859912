const Layout = () =>
    import ('@/layout/index.vue')

export default {
    path: '/userManagement',
    component: Layout,
    redirect: '/userManagement/userList',
    name: 'UserManagement',
    meta: {
        title: '用户管理',
        icon: 'sidebar-basic',
        auth: ['member.getMemberList', 'blackMember.getMemberList', 'lateAll.getDeductScoreList', 'timeOutAll.getDeductScoreList', 'deductionAll.getDeductScoreList', 'deduction.getDeductionList', 'upgrade.getUpgradeList']
    },
    children: [{
        path: 'userList',
        name: '',
        component: () =>
            import ('@/views/user_management/userList/userList.vue'),
        meta: {
            title: '用户列表',
            auth: ['member.getMemberList']
        },
        children: [{
            path: '',
            name: 'userList',
            component: () =>
                import ('@/views/user_management/userList/userList.vue'),
            meta: {
                title: '用户列表',
                sidebar: false,
                cache: ['modifyGrade', 'orderListTwo', 'allergyRecord', 'userDeductPoints', 'userOvertime', 'userLate'],
                auth: ['member.getMemberList', 'member.allergicMember.getAllergyMemberList', 'member.allergicMember.insert', 'member.allergicMember.update', 'member.allergicMember.getAllergyMemberById']
            }
        },
        {
            path: 'orderListTwo',
            name: 'orderListTwo',
            component: () =>
                import ('@/views/user_management/userList/orderListTwo.vue'),
            meta: {
                title: '订单列表',
                sidebar: false,
                cache: ['orderDetailP', 'orderDetail'],
                auth: ['member.getMemberDestineSeatList', 'member.getDestineSeatList', 'member.getMiniReserveGoodsList']
            }
            // children: [
            //     {
            //         path: 'reservation',
            //         name: 'reservation',
            //         component: () => import('@/views/user_management/userList/reservation.vue'),
            //         meta: {
            //             title: '订单列表-订位',
            //             sidebar: false
            //         }
            //     },
            //     {
            //         path: 'varietyOfDishes',
            //         name: 'varietyOfDishes',
            //         component: () => import('@/views/user_management/userList/varietyOfDishes.vue'),
            //         meta: {
            //             title: '订单列表-菜品',
            //             sidebar: false
            //         }
            //     },
            // ]
        },
        {
            path: 'allergyRecord',
            name: 'allergyRecord',
            component: () =>
                import ('@/views/user_management/userList/allergyRecord.vue'),
            meta: {
                title: '过敏记录',
                sidebar: false,
                auth: ['member.allergicMember.getAllergyMemberList', 'member.allergicMember.insert', 'member.allergicMember.update', 'member.allergicMember.getAllergyMemberById']
            }
        },

        {
            path: 'userDeductPoints',
            name: 'userDeductPoints',
            component: () =>
                import ('@/views/user_management/userList/userDeductPoints.vue'),
            meta: {
                title: '扣分记录',
                sidebar: false,
                cache: ['userDeductPointsDetail']
            }
        },
        {
            path: 'userDeductPointsDetail',
            name: 'userDeductPointsDetail',
            component: () =>
                import ('@/views/user_management/userList/userDeductPointsDetail.vue'),
            meta: {
                title: '扣分记录详情',
                sidebar: false
            }
        }, {
            path: 'userOvertime',
            name: 'userOvertime',
            component: () =>
                import ('@/views/user_management/userList/userOvertime.vue'),
            meta: {
                title: '超时记录',
                sidebar: false,
                cache: ['userOvertimeDetali']
            }
        },
        {
            path: 'userOvertimeDetali',
            name: 'userOvertimeDetali',
            component: () =>
                import ('@/views/user_management/userList/userOvertimeDetali.vue'),
            meta: {
                title: '超时记录详情',
                sidebar: false
            }
        }, {
            path: 'userLate',
            name: 'userLate',
            component: () =>
                import ('@/views/user_management/userList/userLate.vue'),
            meta: {
                title: '迟到记录',
                sidebar: false,
                cache: ['userLateDetali1']
            }
        },
        {
            path: 'userLateDetali1',
            name: 'userLateDetali1',
            component: () =>
                import ('@/views/user_management/userList/userLateDatali.vue'),
            meta: {
                title: '迟到记录详情',
                sidebar: false
            }
        },
        {
            path: 'modifyGrade',
            name: 'modifyGrade',
            component: () =>
                import ('@/views/user_management/userList/modifyGrade.vue'),
            meta: {
                title: '修改等级',
                sidebar: false
            }
        }
        ]

    },
    {
        path: 'userLateList',
        name: '',
        component: () =>
            import ('@/views/user_management/userLateList/userLateList.vue'),
        meta: {
            title: '用户迟到列表',
            auth: ['lateAll.getDeductScoreList']
        },
        children: [{
            path: '',
            name: 'userLateList',
            component: () =>
                import ('@/views/user_management/userLateList/userLateList.vue'),
            meta: {
                title: '用户迟到列表',
                sidebar: false,
                auth: ['lateAll.getDeductScoreList'],
                cache: ['userLateDetail', 'userLateDetailForm']
            }
        },
        {
            path: 'userLateDetail',
            name: 'userLateDetail',
            component: () =>
                import ('@/views/user_management/userLateList/detail.vue'),
            meta: {
                title: '详情',
                sidebar: false
            }
        },
        {
            path: 'userLateDetailForm',
            name: 'userLateDetailForm',
            component: () =>
                import ('@/views/user_management/userLateList/userLateDetailForm.vue'),
            meta: {
                title: '详情',
                sidebar: false
            }
        }
        ]
    },
    {
        path: 'userOvertimeList',
        name: '',
        component: () =>
            import ('@/views/user_management/userOvertimeList/userOvertimeList.vue'),
        meta: {
            title: '用户超时列表',
            auth: ['timeOutAll.getDeductScoreList']
        },
        children: [{
            path: '',
            name: 'userOvertimeList',
            component: () =>
                import ('@/views/user_management/userOvertimeList/userOvertimeList.vue'),
            meta: {
                title: '用户超时列表',
                sidebar: false,
                auth: ['timeOutAll.getDeductScoreList'],
                cache: ['userOvertimeDetail', 'userOvertimeDetailForm']
            }
        },
        {
            path: 'userOvertimeDetail',
            name: 'userOvertimeDetail',
            component: () =>
                import ('@/views/user_management/userOvertimeList/detail.vue'),
            meta: {
                title: '详情',
                sidebar: false
            }
        },
        {
            path: 'userOvertimeDetailForm',
            name: 'userOvertimeDetailForm',
            component: () =>
                import ('@/views/user_management/userOvertimeList/detailForm.vue'),
            meta: {
                title: '详情',
                sidebar: false
            }
        }

        ]
    },
    {
        path: 'userDeductionList',
        name: '',
        component: () =>
            import ('@/views/user_management/userDeductionList/userDeductionList.vue'),
        meta: {
            title: '用户扣分记录',
            auth: ['deductionAll.getDeductScoreList']
        },
        children: [{
            path: '',
            name: 'userDeductionList',
            component: () =>
                import ('@/views/user_management/userDeductionList/userDeductionList.vue'),
            meta: {
                title: '用户扣分记录',
                sidebar: false,
                auth: ['deductionAll.getDeductScoreList'],
                cache: ['userDeductionDetail']
            }
        },
        {
            path: 'userDeductionDetail',
            name: 'userDeductionDetail',
            component: () =>
                import ('@/views/user_management/userDeductionList/detail.vue'),
            meta: {
                title: '详情',
                sidebar: false
            }
        }
            // {
            //     path: 'deductionSetting',
            //     name: 'deductionSetting',
            //     component: () => import('@/views/user_management/userDeductionList/deductionSetting.vue'),
            //     meta: {
            //         title: '扣分设置',
            //         sidebar: false
            //     }
            // },

        ]
    },
    {
        path: 'deductionSettingList',
        name: '',
        component: () =>
            import ('@/views/user_management/userDeductionList/deductionSettingList.vue'),
        meta: {
            title: '扣分设置',
            auth: ['deduction.getDeductionList']
        },
        children: [{
            path: '',
            name: 'deductionSettingList',
            component: () =>
                import ('@/views/user_management/userDeductionList/deductionSettingList.vue'),
            meta: {
                title: '扣分设置',
                sidebar: false,
                auth: ['deduction.getDeductionList'],
                cache: ['editDeductionSetting']
            }
        },
        {
            path: 'addDeductionSetting',
            name: 'addDeductionSetting',
            component: () =>
                import ('@/views/user_management/userDeductionList/deductionSetting.vue'),
            meta: {
                title: '新增',
                sidebar: false
            }
        }, {
            path: 'editDeductionSetting',
            name: 'editDeductionSetting',
            component: () =>
                import ('@/views/user_management/userDeductionList/deductionSetting.vue'),
            meta: {
                title: '编辑',
                sidebar: false
            }
        }

        ]
    },
    {
        path: 'memberUpgradeSetList',
        name: '',
        component: () =>
            import ('@/views/user_management/userDeductionList/memberUpgradeSettingList.vue'),
        meta: {
            title: '会员升级设置',
            auth: ['upgrade.getUpgradeList']
        },
        children: [{
            path: '',
            name: 'memberUpgradeSetList',
            component: () =>
                import ('@/views/user_management/userDeductionList/memberUpgradeSettingList.vue'),
            meta: {
                title: '会员升级设置',
                sidebar: false,
                auth: ['upgrade.getUpgradeList'],
                cache: ['membeSetting']
            }
        },
        {
            path: 'addSetting',
            name: 'addSetting',
            component: () =>
                import ('@/views/user_management/userDeductionList/membeSetting.vue.vue'),
            meta: {
                title: '新增',
                sidebar: false
            }
        },
        {
            path: 'membeSetting',
            name: 'membeSetting',
            component: () =>
                import ('@/views/user_management/userDeductionList/membeSetting.vue.vue'),
            meta: {
                title: '编辑',
                sidebar: false
            }
        }

        ]
    },
    {
        path: 'blackList',
        name: '',
        component: () =>
            import ('@/views/user_management/userList/blackList.vue'),
        meta: {
            title: '黑名单管理',
            auth: ['blackMember.getMemberList']
        },
        children: [{
            path: '',
            name: 'blackList',
            component: () =>
                import ('@/views/user_management/userList/blackList.vue'),
            meta: {
                title: '黑名单管理',
                sidebar: false,
                auth: ['blackMember.getMemberList'],
                cache: ['orderListTwoBlack', 'userEditLevelBlack', 'userDeductPointsBlack', 'userOvertimeBlack', 'userLateBlack']
            }
        },
        {
            path: 'orderListTwoBlack',
            name: 'orderListTwoBlack',
            component: () =>
                import ('@/views/user_management/userList/orderListTwo.vue'),
            meta: {
                title: '订单列表',
                sidebar: false
            }
        },
        {
            path: 'userEditLevelBlack',
            name: 'userEditLevelBlack',
            component: () =>
                import ('@/views/user_management/userList/userEditLevel.vue'),
            meta: {
                title: '编辑等级',
                sidebar: false
            }
        }, {
            path: 'userDeductPointsBlack',
            name: 'userDeductPointsBlack',
            component: () =>
                import ('@/views/user_management/userList/black/userDeductPoints.vue'),
            meta: {
                title: '扣分记录',
                sidebar: false,
                cache: ['userDeductPointsDetailBlack']
            }
        },
        {
            path: 'userDeductPointsDetailBlack',
            name: 'userDeductPointsDetailBlack',
            component: () =>
                import ('@/views/user_management/userList/black/userDeductPointsDetail.vue'),
            meta: {
                title: '扣分记录详情',
                sidebar: false
            }
        }, {
            path: 'userOvertimeBlack',
            name: 'userOvertimeBlack',
            component: () =>
                import ('@/views/user_management/userList/black/userOvertime.vue'),
            meta: {
                title: '超时记录',
                sidebar: false,
                cache: ['userOvertimeDetaliBlack']
            }
        },
        {
            path: 'userOvertimeDetaliBlack',
            name: 'userOvertimeDetaliBlack',
            component: () =>
                import ('@/views/user_management/userList/black/userOvertimeDetali.vue'),
            meta: {
                title: '超时记录详情',
                sidebar: false
            }
        }, {
            path: 'userLateBlack',
            name: 'userLateBlack',
            component: () =>
                import ('@/views/user_management/userList/black/userLate.vue'),
            meta: {
                title: '迟到记录',
                sidebar: false,
                cache: ['userLateDetaliBlack']
            }
        },
        {
            path: 'userLateDetaliBlack',
            name: 'userLateDetaliBlack',
            component: () =>
                import ('@/views/user_management/userList/black/userLateDatali.vue'),
            meta: {
                title: '迟到记录详情',
                sidebar: false
            }
        }
        ]
    }
    ]

}
