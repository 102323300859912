const Layout = () =>
    import ('@/layout/index.vue')

export default {
    path: '/storeManagement',
    component: Layout,
    redirect: '/storeManagement/storeList',
    name: 'storeManagement',
    meta: {
        title: '门店管理',
        icon: 'sidebar-basic',
        auth: ['stores.getStoresList']
    },
    children: [{
        path: 'storeList',
        name: '',
        component: () =>
            import ('@/views/store_management/storeList/storeList.vue'),
        meta: {
            title: '门店列表',
            // cache: ['thisSeasonMenu', 'editStore', 'specialTime', 'seatManagement', 'chefManagement', 'environment', 'optionalTime', 'inStorePictures'],
            auth: ['stores.getStoresList', 'stores.insert', 'stores.update', 'stores.storesSeat.getStoresSeatList', 'stores.chooseTime.getChooseTimeList', 'stores.workTime.getWorkTimeList', 'stores.menu.getMenuList', 'stores.cook.getCookList', 'stores.editStoresImages', 'stores.editEnvironImages', 'stores.setStatus', 'stores.setRecommend']
        },
        children: [{
            path: '',
            name: 'storeList',
            component: () =>
                import ('@/views/store_management/storeList/storeList.vue'),
            meta: {
                title: '门店列表',
                cache: ['thisSeasonMenu', 'addStore', 'editStore', 'specialTime', 'seatManagement', 'chefManagement', 'environment', 'optionalTime', 'inStorePictures'],
                sidebar: false

            }
        },
        {
            path: 'editStore',
            name: 'editStore',
            component: () =>
                import ('@/views/store_management/storeList/editStore.vue'),
            meta: {
                title: '编辑',
                sidebar: false
            }
        },
        {
            path: 'addStore',
            name: 'addStore',
            component: () =>
                import ('@/views/store_management/storeList/editStore.vue'),
            meta: {
                title: '新增',
                sidebar: false
            }
        },
        {
            path: 'thisSeasonMenu',
            name: 'thisSeasonMenu',
            component: () =>
                import ('@/views/store_management/thisSeasonMenu/thisSeasonMenu.vue'),
            meta: {
                title: '本季菜单',
                sidebar: false,
                cache: ['addMenu', 'editMenu'],
                auth: ['stores.menu.getMenuList', 'stores.menu.insert', 'stores.menu.update', 'stores.menu.delete', 'stores.menu.getMenuById']
            },
            children: [{
                path: 'editMenu',
                name: 'editMenu',
                component: () =>
                    import ('@/views/store_management/thisSeasonMenu/editMenu.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            }, {
                path: 'addMenu',
                name: 'addMenu',
                component: () =>
                    import ('@/views/store_management/thisSeasonMenu/editMenu.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            }]
        },
        {
            path: 'specialTime',
            name: 'specialTime',
            component: () =>
                import ('@/views/store_management/specialTime/specialTime.vue'),
            meta: {
                title: '特殊营业时间段设置',
                sidebar: false,
                cache: ['addSpecialTime', 'editSpecialTime'],
                auth: ['stores.workTime.getWorkTimeList', 'stores.workTime.insert', 'stores.workTime.update', 'stores.workTime.delete', 'stores.workTime.getWorkTimeById']
            },
            children: [{
                path: 'addSpecialTime',
                name: 'addSpecialTime',
                component: () =>
                    import ('@/views/store_management/specialTime/editSpecialTime.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            },
            {
                path: 'editSpecialTime',
                name: 'editSpecialTime',
                component: () =>
                    import ('@/views/store_management/specialTime/editSpecialTime.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            }
            ]
        },
        {
            path: 'seatManagement',
            name: 'seatManagement',
            component: () =>
                import ('@/views/store_management/seatManagement/seatManagement.vue'),
            meta: {
                title: '座位管理',
                sidebar: false,
                cache: ['addSeatManagement', 'editSeatManagement'],
                auth: ['stores.storesSeat.getStoresSeatList', 'stores.storesSeat.insert', 'stores.storesSeat.update', 'stores.storesSeat.delete', 'stores.storesSeat.getStoresSeatById']
            },
            children: [{
                path: 'addSeatManagement',
                name: 'addSeatManagement',
                component: () =>
                    import ('@/views/store_management/seatManagement/editSeatManagement.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            },
            {
                path: 'editSeatManagement',
                name: 'editSeatManagement',
                component: () =>
                    import ('@/views/store_management/seatManagement/editSeatManagement.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            }
            ]
        },
        {
            path: 'chefManagement',
            name: 'chefManagement',
            component: () =>
                import ('@/views/store_management/chefManagement/chefManagement.vue'),
            meta: {
                title: '主厨管理',
                sidebar: false,
                cache: ['addChef', 'editChef'],
                auth: ['stores.cook.getCookList', 'stores.cook.insert', 'stores.cook.update', 'stores.cook.delete', 'stores.cook.getCookById']
            },
            children: [{
                path: 'addChef',
                name: 'addChef',
                component: () =>
                    import ('@/views/store_management/chefManagement/editChef.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            },
            {
                path: 'editChef',
                name: 'editChef',
                component: () =>
                    import ('@/views/store_management/chefManagement/editChef.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            }
            ]
        },
        {
            path: 'environment',
            name: 'environment',
            component: () =>
                import ('@/views/store_management/storeList/environment.vue'),
            meta: {
                title: '环境照片管理',
                sidebar: false
            }
        },
        {
            path: 'optionalTime',
            name: 'optionalTime',
            component: () =>
                import ('@/views/store_management/optionalTime/optionalTime.vue'),
            meta: {
                title: '可选时间段管理',
                sidebar: false,
                cache: ['addOptional', 'editOptional'],
                auth: ['stores.chooseTime.getChooseTimeList', 'stores.chooseTime.insert', 'stores.chooseTime.update', 'stores.chooseTime.delete', 'stores.chooseTime.getChooseTimeById']
            },
            children: [{
                path: 'addOptional',
                name: 'addOptional',
                component: () =>
                    import ('@/views/store_management/optionalTime/editOptional.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            },
            {
                path: 'editOptional',
                name: 'editOptional',
                component: () =>
                    import ('@/views/store_management/optionalTime/editOptional.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            }
            ]
        },
        {
            path: 'inStorePictures',
            name: 'inStorePictures',
            component: () =>
                import ('@/views/store_management/storeList/inStorePictures.vue'),
            meta: {
                title: '店内详情图',
                sidebar: false
            }
        }
        ]

    }]

}
