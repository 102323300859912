const Layout = () =>
    import('@/layout/index.vue')

export default {
    path: '/seatsManagement',
    component: Layout,
    redirect: '/seatsManagement/storeList',
    name: 'seatsManagement',
    meta: {
        title: '座位管理',
        icon: 'sidebar-basic',
        auth: ['seatArea.getSeatAreaList', 'seatType.getseatTypeList']
    },
    children: [
        {
            path: 'cloudlist',
            name: '',
            component: () => import('@/views/seatsManagement/cloud_list/list.vue'),
            meta: {
                title: '客如云桌号管理'
                // cache: ['addRegion', 'editRegion'],
                // auth: ['seatArea.getSeatAreaList']
            },
            children: [{
                path: 'cloudtablelist',
                name: 'cloudtablelist',
                component: () =>
                    import('@/views/seatsManagement/cloud_list/cloudtablelist.vue'),
                meta: {
                    title: '客如云桌号管理',
                    sidebar: false,
                    auth: ['seatType.getseatTypeList']
                },
                children: [{
                    path: 'addcloudtablelist',
                    name: 'addcloudtablelist',
                    component: () =>
                        import('@/views/seatsManagement/cloud_list/relationForm.vue'),
                    meta: {
                        title: '',
                        sidebar: false,
                        auth: ['seatType.getseatTypeList']
                    }
                }]
            }]
        },
        {
            path: 'seatsRegion',
            name: '',
            component: () =>
                import('@/views/seatsManagement/seatsRegion/seatsRegion.vue'),
            meta: {
                title: '座位区域管理',
                cache: ['addRegion', 'editRegion'],
                auth: ['seatArea.getSeatAreaList']
            },
            children: [{
                path: '',
                name: 'seatsRegion',
                component: () =>
                    import('@/views/seatsManagement/seatsRegion/seatsRegion.vue'),
                meta: {
                    title: '座位区域管理',
                    sidebar: false,

                    auth: ['seatArea.getSeatAreaList']
                }
            },
            {
                path: 'editRegion',
                name: 'editRegion',
                component: () =>
                    import('@/views/seatsManagement/seatsRegion/editRegion.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            }, {
                path: 'addRegion',
                name: 'addRegion',
                component: () =>
                    import('@/views/seatsManagement/seatsRegion/editRegion.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            }
            ]

        },
        {
            path: 'seatsType',
            name: '',
            component: () =>
                import('@/views/seatsManagement/seatsType/seatsType.vue'),
            meta: {
                title: '座位类型管理',
                cache: ['addType', 'editType'],
                auth: ['seatType.getseatTypeList']
            },
            children: [{
                path: 'seatsType',
                name: '',
                component: () =>
                    import('@/views/seatsManagement/seatsType/seatsType.vue'),
                meta: {
                    title: '座位类型管理',
                    sidebar: false,

                    auth: ['seatType.getseatTypeList']
                }
            },
            {
                path: 'editType',
                name: 'editType',
                component: () =>
                    import('@/views/seatsManagement/seatsType/editType.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            },
            {
                path: 'addType',
                name: 'addType',
                component: () =>
                    import('@/views/seatsManagement/seatsType/editType.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            }
            ]

        }
    ]

}
