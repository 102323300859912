const Layout = () =>
    import('@/layout/index.vue')

export default {
    path: '/commodity',
    component: Layout,
    redirect: '/commodity/storeList',
    name: 'commodity',
    meta: {
        title: '商品管理',
        icon: 'sidebar-basic',
        auth: ['goods.getGoodList', 'goodsCategory.getCategoryList', 'goodsAttribute.getAttributeList']
    },
    children: [
        {
            path: 'cloudlist',
            name: '',
            component: () =>
                import('@/views/commodityManagement/cloudlist/cloudlist.vue'),
            meta: {
                title: '客如云商品管理'
                // auth: ['goods.getGoodList']
            },
            children: [{
                path: 'cloudproductList',
                name: 'cloudproductList',
                component: () =>
                    import('@/views/commodityManagement/cloudlist/cloudproductList.vue'),
                meta: {
                    title: '客如云商品管理',
                    sidebar: false,
                    auth: ['seatType.getseatTypeList']
                }
            }]
        },

        {
            path: 'productList',
            name: '',
            component: () =>
                import('@/views/commodityManagement/productList/productList.vue'),
            meta: {
                title: '商品管理',
                auth: ['goods.getGoodList']
            },
            children: [{
                path: '',
                name: 'productList',
                component: () =>
                    import('@/views/commodityManagement/productList/productList.vue'),
                meta: {
                    title: '商品管理',
                    sidebar: false,
                    cache: ['addProduct', 'editProduct', 'seeProduct', 'setProduct'],
                    auth: ['goods.getGoodList']
                }
            },
            {
                path: 'addProduct',
                name: 'addProduct',
                component: () =>
                    import('@/views/commodityManagement/productList/editProduct.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            },
            {
                path: 'editProduct',
                name: 'editProduct',
                component: () =>
                    import('@/views/commodityManagement/productList/editProduct.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            },
            {
                path: 'seeProduct',
                name: 'seeProduct',
                component: () =>
                    import('@/views/commodityManagement/productList/see.vue'),
                meta: {
                    title: '详情',
                    sidebar: false
                }
            }, {
                path: 'setProduct',
                name: 'setProduct',
                component: () =>
                    import('@/views/commodityManagement/productList/set.product.vue'),
                meta: {
                    title: '库存设置',
                    sidebar: false
                }
            }
            ]

        },
        {
            path: 'categoryList',
            name: '',
            component: () =>
                import('@/views/commodityManagement/categoryList/categoryList.vue'),
            meta: {
                title: '类目管理',
                auth: ['goodsCategory.getCategoryList']
            },
            children: [{
                path: '',
                name: 'categoryList',
                component: () =>
                    import('@/views/commodityManagement/categoryList/categoryList.vue'),
                meta: {
                    title: '类目管理',
                    sidebar: false,
                    cache: ['addCategory', 'editCategory'],
                    auth: ['goodsCategory.getCategoryList']
                }
            },
            {
                path: 'editCategory',
                name: 'editCategory',
                component: () =>
                    import('@/views/commodityManagement/categoryList/editCategory.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            },
            {
                path: 'addCategory',
                name: 'addCategory',
                component: () =>
                    import('@/views/commodityManagement/categoryList/editCategory.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            }
            ]
        },
        {
            path: 'attributeList',
            name: '',
            component: () =>
                import('@/views/commodityManagement/attributeList/attributeList.vue'),
            meta: {
                title: '属性管理',
                auth: ['goodsAttribute.getAttributeList']
            },
            children: [{
                path: '',
                name: 'attributeList',
                component: () =>
                    import('@/views/commodityManagement/attributeList/attributeList.vue'),
                meta: {
                    title: '属性管理',
                    sidebar: false,
                    cache: ['addAttribute', 'editAttribute'],
                    auth: ['goodsAttribute.getAttributeList']
                }
            },
            {
                path: 'editAttribute',
                name: 'editAttribute',
                component: () =>
                    import('@/views/commodityManagement/attributeList/editAttribute.vue'),
                meta: {
                    title: '编辑',
                    sidebar: false
                }
            },
            {
                path: 'addAttribute',
                name: 'addAttribute',
                component: () =>
                    import('@/views/commodityManagement/attributeList/editAttribute.vue'),
                meta: {
                    title: '新增',
                    sidebar: false
                }
            }
            ]

        }
    ]

}
