const Layout = () => import('@/layout/index.vue')

export default {
    path: '/reservationOrder',
    component: Layout,
    redirect: '/reservationOrder/orderList',
    name: 'reservationOrder',
    meta: {
        title: '订位订单',
        icon: 'sidebar-basic',
        auth: ['destineSeat.getDestineSeatList', 'refund.getRefundRecordList']
    },
    children: [
        {
            path: 'orderList',
            name: '',
            component: () => import('@/views/reservationOrder/orderList/orderList.vue'),
            meta: {
                title: '订位订单',
                auth: ['destineSeat.getDestineSeatList']
            },
            children: [
                {
                    path: '',
                    name: 'orderList',
                    component: () => import('@/views/reservationOrder/orderList/orderList.vue'),
                    meta: {
                        title: '订位订单',
                        sidebar: false,
                        cache: ['backstageReservation', 'orderDetailP'],
                        auth: ['destineSeat.getDestineSeatList']
                    }
                },
                {
                    path: 'backstageReservation',
                    name: 'backstageReservation',
                    component: () => import('@/views/reservationOrder/orderList/backstageReservation.vue'),
                    meta: {
                        title: '后台订位',
                        sidebar: false
                    }
                },
                {
                    path: 'orderDetailP',
                    name: 'orderDetailP',
                    component: () => import('@/views/reservationOrder/orderList/orderDetail.vue'),
                    meta: {
                        title: '订单详情',
                        sidebar: false
                    }
                }
            ]
        },
        {
            path: 'refundList',
            name: '',
            component: () => import('@/views/reservationOrder/refundList/refundList.vue'),
            meta: {
                title: '退款列表',
                auth: ['refund.getRefundRecordList']
            },
            children: [
                {
                    path: '',
                    name: 'refundList',
                    component: () => import('@/views/reservationOrder/refundList/refundList.vue'),
                    meta: {
                        title: '退款列表',
                        sidebar: false,
                        auth: ['refund.getRefundRecordList']
                    }
                }
            ]
        }
    ]

}
