const Layout = () =>
    import ('@/layout/index.vue')

export default {
    path: '/clerkManagement',
    component: Layout,
    redirect: '/clerkManagement/clerkList',
    name: 'clerkManagement',
    meta: {
        title: '店员管理',
        icon: 'sidebar-basic',
        auth: ['staff.getStaffList', 'appRole.getAppRoleList']
    },
    children: [{
        path: 'clerkList',
        name: '',
        component: () =>
            import ('@/views/clerkManagement/clerkList/clerkList.vue'),
        meta: {
            title: '店员管理',

            auth: ['staff.getStaffList']
        },
        children: [{
            path: '',
            name: 'clerkList',
            component: () =>
                import ('@/views/clerkManagement/clerkList/clerkList.vue'),
            meta: {
                title: '店员管理',
                sidebar: false,
                cache: ['editClerk'],
                auth: ['staff.getStaffList']
            }
        },
        {
            path: 'editClerk',
            name: 'editClerk',
            component: () =>
                import ('@/views/clerkManagement/clerkList/editClerk.vue'),
            meta: {
                title: '编辑',
                sidebar: false
            }
        },
        {
            path: 'addClerk',
            name: 'addClerk',
            component: () =>
                import ('@/views/clerkManagement/clerkList/editClerk.vue'),
            meta: {
                title: '新增',
                sidebar: false
            }
        }
        ]
    },
    {
        path: 'roleGroup',
        name: '',
        component: () =>
            import ('@/views/clerkManagement/roleGroup/roleGroup.vue'),
        meta: {
            title: '角色组管理',
            auth: ['appRole.getAppRoleList']
        },
        children: [{
            path: '',
            name: 'roleGroup',
            component: () =>
                import ('@/views/clerkManagement/roleGroup/roleGroup.vue'),
            meta: {
                title: '角色组管理',
                sidebar: false,
                cache: ['addRoleGroup', 'editRoleGroup'],
                auth: ['appRole.getAppRoleList']
            }
        },
        {
            path: 'editRoleGroup',
            name: 'editRoleGroup',
            component: () =>
                import ('@/views/clerkManagement/roleGroup/editRoleGroup.vue'),
            meta: {
                title: '编辑',
                sidebar: false
            }
        }, {
            path: 'addRoleGroup',
            name: 'addRoleGroup',
            component: () =>
                import ('@/views/clerkManagement/roleGroup/editRoleGroup.vue'),
            meta: {
                title: '新增',
                sidebar: false
            }
        }
        ]
    }
    ]

}
