const Layout = () => import('@/layout/index.vue')

export default {
    path: '/financialStatistics',
    component: Layout,
    redirect: '/financialStatistics/evaluationList',
    name: 'financialStatistics',
    meta: {
        title: '财务管理',
        icon: 'sidebar-basic',
        auth: ['finance.getIncomeStatistics', 'finance.getStoresGoodsTotal']
    },
    children: [
        {
            path: 'incomeStatistics',
            name: 'incomeStatistics',
            component: () => import('@/views/financialStatistics/incomeStatistics/incomeStatistics.vue'),
            meta: {
                title: '平台收入统计',
                auth: ['finance.getIncomeStatistics']
            },
            children: [
                {
                    path: '',
                    name: 'incomeStatistics',
                    component: () => import('@/views/financialStatistics/incomeStatistics/incomeStatistics.vue'),
                    meta: {
                        title: '平台收入统计',
                        sidebar: false,
                        auth: ['finance.getIncomeStatistics']
                    }
                }
            ]
        },
        {
            path: 'dishStatistics',
            name: '',
            component: () => import('@/views/financialStatistics/dishStatistics/dishStatistics.vue'),
            meta: {
                title: '门店菜品统计',
                auth: ['finance.getStoresGoodsTotal']
            },
            children: [
                {
                    path: '',
                    name: 'dishStatistics',
                    component: () => import('@/views/financialStatistics/dishStatistics/dishStatistics.vue'),
                    meta: {
                        title: '门店菜品统计',
                        sidebar: false,
                        cache: ['dishStatisticsDetail'],
                        auth: ['finance.getStoresGoodsTotal']
                    }
                },
                {
                    path: 'dishStatisticsDetail',
                    name: 'dishStatisticsDetail',
                    component: () => import('@/views/financialStatistics/dishStatistics/dishStatisticsDetail.vue'),
                    meta: {
                        title: '详情',
                        sidebar: false
                    }
                }
            ]
        }
    ]

}
