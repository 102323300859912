const Layout = () =>
    import ('@/layout/index.vue')

export default {
    path: '/allergenManagement',
    component: Layout,
    redirect: '/allergenManagement/allergenList',
    name: 'allergenManagement',
    meta: {
        title: '过敏源管理',
        icon: 'sidebar-basic',
        auth: ['allergy.getAllergyList']
    },
    children: [{
        path: 'allergenList',
        name: '',
        component: () =>
            import ('@/views/allergenManagement/allergenList/allergenList.vue'),
        meta: {
            title: '过敏源管理',
            auth: ['allergy.getAllergyList']
        },
        children: [{
            path: '',
            name: 'allergenList',
            component: () =>
                import('@/views/allergenManagement/allergenList/allergenList.vue'),
            meta: {
                title: '过敏源管理',
                sidebar: false,
                cache: ['addAllergen', 'editAllergen'],
                auth: ['allergy.getAllergyList']
            }
        }, {
            path: 'editAllergen',
            name: 'editAllergen',
            component: () =>
                import ('@/views/allergenManagement/allergenList/editAllergen.vue'),
            meta: {
                title: '编辑',
                sidebar: false
            }
        }, {
            path: 'addAllergen',
            name: 'addAllergen',
            component: () =>
                import ('@/views/allergenManagement/allergenList/editAllergen.vue'),
            meta: {
                title: '新增',
                sidebar: false
            }
        }]
    }]

}
