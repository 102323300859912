import { auth, authAll } from '@/util'

export default function directive(app) {
    // 注册 v-auth-sys 和 v-auth-sys-all 指令
    app.directive('auth-sys', {
        mounted: (el, binding) => {
            console.log('binding', binding.value)
            if (!auth(binding.value)) {
                el.remove()
            }
        }
    })
    app.directive('auth-all-sys', {
        mounted: (el, binding) => {
            if (!authAll(binding.value)) {
                el.remove()
            }
        }
    })
}
