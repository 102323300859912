const Layout = () => import('@/layout/index.vue')

export default {
    path: '/dishOrderManagement',
    component: Layout,
    redirect: '/dishOrderManagement/dishOrderList',
    name: 'dishOrderManagement',
    meta: {
        title: '菜品订单',
        icon: 'sidebar-basic',
        auth: ['menuOrder.getMiniReserveGoodsList']
    },
    children: [
        {
            path: 'dishOrderList',
            name: '',
            component: () => import('@/views/dishOrderManagement/dishOrderList/dishOrderList.vue'),
            meta: {
                title: '菜品订单',
                auth: ['menuOrder.getMiniReserveGoodsList']
            },
            children: [
                {
                    path: '',
                    name: 'dishOrderList',
                    component: () => import('@/views/dishOrderManagement/dishOrderList/dishOrderList.vue'),
                    meta: {
                        title: '菜品订单',
                        sidebar: false,
                        cache: ['orderDetail']
                    }
                },
                {
                    path: 'orderDetail',
                    name: 'orderDetail',
                    component: () => import('@/views/dishOrderManagement/dishOrderList/dishOrderDetail.vue'),
                    meta: {
                        title: '订单详情',
                        sidebar: false
                    }
                }
            ]
        }
        // {
        //     path: 'dishRefundList',
        //     name: 'dishRefundList',
        //     component: () => import('@/views/dishOrderManagement/dishRefundList/dishRefundList.vue'),
        //     meta: {
        //         title: '退款列表'
        //     },
        //     children: []
        // }
    ]

}
