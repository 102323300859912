const Layout = () => import('@/layout/index.vue')

export default {
    path: '/generalSettings',
    component: Layout,
    redirect: '/generalSettings/evaluationList',
    name: 'generalSettings',
    meta: {
        title: '通用设置',
        icon: 'sidebar-basic',
        auth: ['aboutUs.getAboutUs', 'question.getQuestionList']
    },
    children: [
        {
            path: 'aboutUs',
            name: 'aboutUs',
            component: () => import('@/views/generalSettings/aboutUs.vue'),
            meta: {
                title: '通用设置',
                auth: ['aboutUs.getAboutUs']
            },
            children: [
                {
                    path: '',
                    name: 'aboutUs',
                    component: () => import('@/views/generalSettings/aboutUs.vue'),
                    meta: {
                        title: '通用设置',
                        sidebar: false,
                        auth: ['aboutUs.getAboutUs']
                    }
                }
            ]
        },
        {
            path: 'commonProblem',
            name: 'commonProblem',
            component: () => import('@/views/generalSettings/commonProblem.vue'),
            meta: {
                title: '常见问题及解答',
                auth: ['question.getQuestionList']
            },
            children: [
                {
                    path: '',
                    name: 'commonProblem',
                    component: () => import('@/views/generalSettings/commonProblem.vue'),
                    meta: {
                        title: '常见问题及解答',
                        sidebar: false,
                        auth: ['question.getQuestionList']
                    }
                }
            ]
        }
    ]

}
