<template>
    <div>
        <page-header :title="$route.name == 'routerName' ? '新增默认模块' : '编辑默认模块'">
            <el-button icon="el-icon-arrow-left" size="default" round @click="goBack">返 回</el-button>
        </page-header>
        <page-main>
            <el-row>
                <el-col :md="24" :lg="16">
                    <DetailForm :id="$route.params.id" ref="form" />
                </el-col>
            </el-row>
        </page-main>
        <fixed-action-bar>
            <el-button type="primary" size="large" @click="onSubmit">提 交</el-button>
            <el-button size="large" @click="onCancel">取 消</el-button>
        </fixed-action-bar>
    </div>
</template>

<script setup name="IndexDetail">
import DetailForm from './components/DetailForm/index.vue'
import useSettingsStore from '@/store/modules/settings'

const { proxy } = getCurrentInstance()
const settingsStore = useSettingsStore()

function onSubmit() {
    proxy.$refs['form'].submit(() => {
        proxy.$eventBus.emit('get-data-list')
        goBack()
    })
}

function onCancel() {
    goBack()
}

// 返回列表页
function goBack() {
    if (settingsStore.tabbar.enable && !settingsStore.tabbar.mergeTabs) {
        proxy.$tabbar.close({ name: 'pagesExampleGeneralFormModeList' })
    } else {
        proxy.$router.push({ name: 'pagesExampleGeneralFormModeList' })
    }
}
</script>

<style lang="scss" scoped>
// scss
</style>
