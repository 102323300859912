const Layout = () => import('@/layout/index.vue')

export default {
    path: '/evaluationManagement',
    component: Layout,
    redirect: '/evaluationManagement/evaluationList',
    name: 'evaluationManagement',
    meta: {
        title: '评价管理',
        icon: 'sidebar-basic',
        auth: ['comment.getCommentList']
    },
    children: [
        {
            path: 'evaluationList',
            name: '',
            component: () => import('@/views/evaluationManagement/evaluationList/evaluationList.vue'),
            meta: {
                title: '订单评价管理',
                // cache: ['evaluationDetail'],
                auth: ['comment.getCommentList']
            },
            children: [
                {
                    path: '',
                    name: 'evaluationList',
                    component: () => import('@/views/evaluationManagement/evaluationList/evaluationList.vue'),
                    meta: {
                        title: '订单评价管理',
                        sidebar: false,
                        cache: ['evaluationDetail']
                    }
                },
                {
                    path: 'evaluationDetail',
                    name: 'evaluationDetail',
                    component: () => import('@/views/evaluationManagement/evaluationList/evaluationDetail.vue'),
                    meta: {
                        title: '详情',
                        sidebar: false
                    }
                }
            ]
        }
    ]

}
