const Layout = () => import('@/layout/index.vue')

export default {
    path: '/basic/settings',
    component: Layout,
    redirect: '/basic/settings/personnel',
    name: 'basicSettings',
    meta: {
        title: '基本设置',
        icon: 'sidebar-basic',
        auth: ['role.list', 'user.list', 'log.list']
    },

    children: [
        {
            path: 'personnel',
            name: 'basicSettingsPersonnel',
            component: () => import('@/views/basic_settings/personnel.vue'),
            meta: {
                title: '账号管理',
                auth: ['user.list']
            },
            children: [
                {
                    path: 'add',
                    name: 'PersonnelAdd',
                    component: () => import('@/views/basic_settings/personnel/add.personnel.vue'),
                    meta: {
                        title: '新增账户',
                        sidebar: false,
                        activeMenu: '/basic/settings/personnel'
                    }
                },

                {
                    path: 'edit',
                    name: 'PersonnelEdit',
                    component: () => import('@/views/basic_settings/personnel/add.personnel.vue'),
                    meta: {
                        title: '编辑账户',
                        sidebar: false,
                        activeMenu: '/basic/settings/personnel'
                    }
                }
            ]
        },
        {
            path: 'roles',
            name: 'basicSettingsRoles',
            component: () => import('@/views/basic_settings/roles.vue'),
            meta: {
                title: '角色管理',
                auth: ['role.list']
            },
            children: [
                {
                    path: 'add',
                    name: 'basicSettingsRolesAdd',
                    component: () => import('@/views/basic_settings/roles/add.role.vue'),
                    meta: {
                        title: '新增角色',
                        sidebar: false,
                        activeMenu: '/basic/settings/roles'
                    }
                },
                {
                    path: 'edit',
                    name: 'basicSettingsRolesEdit',
                    component: () => import('@/views/basic_settings/roles/add.role.vue'),
                    meta: {
                        title: '编辑角色',
                        sidebar: false,
                        activeMenu: '/basic/settings/roles'
                    }
                }
            ]
        },
        {
            path: 'logs',
            name: 'basicSettingsLogs',
            component: () => import('@/views/basic_settings/logs.vue'),
            meta: {
                title: '操作日志',
                auth: ['log.list']
            }
        }
    ]
}
